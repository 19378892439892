<template>
    <div id="header2">
        <div class="conteneurHeaderHaut">
        <div class="headerHaut">
            <div class="gauche colonne">
                <div id="logoLorfolio" class="itemLogo" v-if="markOrientest">
                    <a href="/" class="" title="Mon Orient'Est"  v-if="!sitePro">
                        <img src="../assets/img/header/logoMonOrientest.svg" alt="Lorfolio" class="centrer" />
                    </a>
                    <a href="/" class="" title="Orient'Est Pro"  v-if="sitePro">
                        <img src="../assets/img/pro/header/logoOrientestPro.svg" alt="Lorfolio" class="centrer" />
                    </a>
                </div>
                <div id="logoLorfolio" class="itemLogo" v-if="!markOrientest">
                    <a href="/" class="" title="Lorfolio"  v-if="!sitePro">
                        <img src="../assets/img/header/logoLorfolio.svg" alt="Lorfolio" class="centrer" />
                    </a>
                    <a href="/" class="" title="Lorfolio"  v-if="sitePro">
                        <img src="../assets/img/pro/header/logoLorfolioPro.svg" alt="Lorfolio" class="centrer" />
                    </a>
                </div>
                <h1 id="prepare" class="itemLogo">
                    <span  v-if="!sitePro">
                        Toujours préparé<br/>pour mes choix professionnels
                    </span>
                    <span  v-if="sitePro && !markOrientest">
                        Faciliter les choix professionnels
                    </span>
                    <span  v-if="sitePro && markOrientest">
                        Outiller vos pratiques
                    </span>
                </h1>
            </div>
            <div class="colonne">
                <h2>{{page}}</h2>
            </div>
            <div v-if="$parent.$parent.addeoLarge || $parent.$parent.addeoLargeLow" class="droite colonne" v-bind:class="{logoRGE : $router.options.paramNouveauLogo}">
                <div id="logoGE" class="itemLogo">
                    <a href="https://www.grandest.fr" target="_blank" rel="noopener" class="" title="Région Grand Est">
                        <img :src="logoRGE" alt="Région Grand Est" class="centrer" v-bind:class="{logoRGE : $router.options.paramNouveauLogo}" />
                    </a>
                </div>
            </div>
        </div>
        </div>
        <div class="ariane" v-if="!sitePro">
            <router-link to="/"><i class="fa fa-home"></i>Accueil</router-link>
            <span><i class="fa fa-angle-right"></i></span>{{page}}
        </div>
        <div class="ariane arianePro" v-if="sitePro">
            <router-link to="/pro"><i class="fa fa-home"></i>Accueil</router-link>
            <span><i class="fa fa-angle-right"></i></span>{{page}}
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header2',
    props: {
        page: String
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed: {
        sitePro() {
            return(this.$parent.$parent.sitePRO);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        logoRGE() {
            if(this.$router.options.paramNouveauLogo) {
                return require("../assets/img/header/logoRGE.svg");
            }
            else {
                return require("../assets/img/header/logoGrandE.svg");
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .addeoSmall  {
        #header2 {
            .headerHaut {
                h2 {
                    font-size:24px !important;
                }
            }
        }
    }
    .addeoMedium:not(.addeoLargeLow), .addeoSmall  {

        #header2 {


            .headerHaut {
                min-height: 0;
                border-bottom: 10px solid #F3C935;

                .colonne {
                    min-height: 0;
                    width:45%;
                }
                h2 {
                    /*margin-top: 60px;*/
                    font-size:3.4vw;
                    /*font-size:4vw ;*/
                    vertical-align: bottom
                }
                .gauche {
                background-image: none;
                }


                .itemLogo {

                    &#logoLorfolio {
                    }
                    &#prepare {
                        /*
                        font-size:2.8vw ;
                        */
                        font-size:2.15vw ;
                        margin-bottom: 30px;
                    }
                }
            }
        }

        &:not(.pro) {
            #header2 {
                .headerHaut {
                    .itemLogo {
                        &#prepare {
                            font-size:1.3em ;
                            @media screen and (max-width: 890px) {
                                font-size:2.3vw ;
                            }

                        }
                    }
                }
            }
        }
    }

    .addeoSmall  {
        #header2 {
            .headerHaut {
                text-align: center;
                .colonne {
                    margin:0 auto;
                    width:90%;
                    max-width: 360px;
                    h2 {
                        margin:1.4em 0 1em;
                    }
                    .itemLogo {
                        &#prepare {
                            font-size:1.15em;
                            /*
                            font-size:1.6em ;
                            */
                        }
                    }
                }
            }
            .ariane {
                font-size:1em;
                margin-bottom: 1em;
            }
        }
        &:not(.pro) {
            #header2 {
                .headerHaut {
                    .itemLogo {
                        &#prepare {
                            font-size:1.15em;
                            @media screen and (max-width: 399px) {
                                font-size:4.6vw ;
                            }
                        }
                    }
                }
            }
        }
        &.pro {
            #header2 {
                .headerHaut {
                    .colonne {
                        min-height: 100px;
                    }
                }
            }
        }
    }

    .pro {
        #header2 {

            .conteneurHeaderHaut {
                background-image: none;
            }

            .headerHaut {
                border-bottom: 8px solid #35AC98 !important;
                min-height: 100px;

                .gauche {
                    background-image: url("~@/assets/img/pro/header/vert.png");
                }
                h2 {
                    /*
                    margin-top: 1em;
                    transform: none;
                    */
                    line-height: 1em;

                    @media screen and (min-width: 1760px) {
                        /*font-size:2.4em ;*/
                        max-width: 600px;
                    }
                }
                .colonne {
                    padding:0 0 0 1%;
                    /*max-height: 182px;*/
                    min-height: 130px;
                }
                .gauche {
                    padding: 0 30px 0 5%;
                }
                .droite {
                    /*padding: 0 4% 0 3%;*/
                    padding: 0 20px 0 3%;
                }
            }
        }

        &.addeoMedium:not(.addeoLargeLow)  {
            #header2 {
                .headerHaut {
                    h2 {
                        margin-top: 62px;
                    }
                    .colonne {
                        max-height: 122px;
                        width:43%;
                    }
                    .gauche {
                        padding-right:3%;
                    }
                }
            }
        }

        &.addeoSmall  {
            #header2 {
                .headerHaut {
                    .gauche {
                        background-image: none;
                        padding:0;
                    }
/*
                    h2 {
                        max-width: 340px;
                    }
*/
                }
            }
        }
    }


    #header2 {
        width:100%;
        height:auto;

        .conteneurHeaderHaut {
            background: no-repeat center bottom #F4CE45 url("~@/assets/img/header/fond2.jpg");
        }
        .headerHaut {
            min-height: 182px;
            background-color: transparent;
            border-bottom: 10px solid #F3C935;
            text-align: left;
            /*background: no-repeat center bottom transparent url("~@/assets/img/header/bas.png");
            padding-bottom:1em;*/

            .colonne {
                min-height: 182px;
                display:inline-block;
                width:35%;
                vertical-align: top;
                color:#4B3C8F;
                text-transform: uppercase;
                text-align: center;
                padding:0;
            }
            h2 {
                /*margin-top: 91px;*/
                margin-top: 60px;
                /* margin-top: 2em; PRO est pas pour .fr ? */
                transform: translateY(-50%);
                /*font-size:4vw ;*/
                font-size:30px ;
                font-weight: 600;
                @media screen and (min-width: 1200px) {
                    /*font-size:3em ;*/
                    font-size:30px ;
                }
            }
            .gauche {
                text-align: left;
                max-width: 405px;
                padding: 0 3% 0 5%;
                background: no-repeat right bottom transparent url("~@/assets/img/header/vert.png");
            }
            .droite {
                width:14%;
                text-align: right;
                padding: 0 5% 0 3%;

                &.logoRGE {
                    float:right;
                }

                img {
                    /*margin-top: 91px;*/
                    margin-top: 60px;
                    transform: translateY(-80%);
                    max-width: 120px;
                    &.logoRGE {
                        max-width: 140px;
                        transform: translateY(-50%);
                        min-width: 106px;
                    }
                }
            }

            .itemLogo {
                padding: 0;
                margin: 0;

                &#logoLorfolio {
                    width:100%;
                    margin-top:30px;
                }
                &#prepare {
                    margin-top:10px;
                    text-align: left;
                    font-size:2.4vw ;
                    line-height: 1.1em;
                    font-weight: normal;

                    @media screen and (min-width: 1200px) {
                        font-size:1.8em ;
                    }
                }
            }
        }
        &:not(.pro), &.pro { /* ne sert à rien mais permet de surpasser le code ci-dessus */
            .itemLogo {
                &#prepare {
                    font-size:1.75vw ;

                    @media screen and (min-width: 1200px) {
                        font-size:1.3em ;
                    }
                }
            }
        }

    }
    #app {
        .ariane {
            text-align: left;
            font-size:1.2em;
            color:#33AD98;
            padding:1em 0 1em 2%;
            background: no-repeat center bottom transparent url("~@/assets/img/barre.svg");
            max-width: 980px;
            width:98%;
            margin:0 auto 3em;

            span {
                margin:0 1em;
                color : #33AD98;
            }
            a {
                color : #4B3C8F;
                &:hover, &:focus {
                    color : #33AD98;
                    border-bottom: 1px solid #33AD98;
                }
                .fa {
                    margin-right: 0.6em;
                }
            }

            &.arianePro {
                color : #4B3C8F;

                a {
                    color : #33AD98;
                    &:hover, &:focus {
                        color : #4B3C8F;
                        border-bottom: 1px solid #4B3C8F;
                    }
                    .fa {
                        margin-right: 0.6em;
                    }
                }

            }
        }
    }
</style>
