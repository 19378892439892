<template>
  <div id="partenaires">
    <bloc-header2 page="Partenaires" />

    <div id="container-partenaires" class="containerAffichage">

        <div class="title">

            <h1>Orient'Est est un service proposé par la <span class="rge">Région Grand Est</span></h1>

            <div class="partenaires">
                <div class="bloc-partenaires">
                    <a href="https://www.grandest.fr/" target="_blank" rel="noopener" title="Région Grand Est">
                        <img :src="logoRGE" alt="Région Grand Est" width="250" height="auto" v-bind:class="{logoRGE : $router.options.paramNouveauLogo}" />
                        <div class="partenaires-legende">Région Grand Est</div>
                    </a>
                </div>
                <p class="partenariat">en partenariat avec les signataires de la charte du Service Public Régional de l'Orientation</p>
            </div>
        </div>

        <div class="partenaires">
            <div class="partenaires-devide">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>

            <div class="bloc-partenaires"><a href="http://www.prefectures-regions.gouv.fr/grand-est" target="_blank" rel="noopener" title="Préfecture de la Région Grand Est"> <img src="../assets/img/partenaires/01prge.png" alt="Préfecture de la Région Grand Est" width="250" height="163" />
            <div class="partenaires-legende">Préfecture de la Région Grand Est</div>
            </a></div>

            <div class="partenaires-devide">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>

            <div class="bloc-partenaires">
                <a href="http://draaf.grand-est.agriculture.gouv.fr" target="_blank" rel="noopener" title="Direction Régionale de l'Alimentation, de l'Agriculture et de la Forêt Grand Est"> <img src="../assets/img/partenaires/03draafge.png" alt="Direction Régionale de l'Alimentation, de l'Agriculture et de la Forêt Grand Est" width="250" height="313" />
                    <div class="partenaires-legende">Direction Régionale de l'Alimentation, de l'Agriculture et de la Forêt Grand Est</div>
                </a>
                <a href="http://www.ac-reims.fr/cid105679/region-academique-grand-est.html" target="_blank" rel="noopener" title="Région académique Grand Est">
                    <img src="../assets/img/partenaires/04acage.png" alt="Région académique Grand Est" width="250" height="400" />
                    <div class="partenaires-legende">Région académique Grand Est</div>
                </a>
            </div>

            <div class="partenaires-devide">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>

            <div class="bloc-partenaires">
                <a href="https://www.francetravail.fr" target="_blank" rel="noopener" title="France Travail">
                    <img src="../assets/img/partenaires/13frtr.png" alt="France Travail" width="250" height="128" />
                    <div class="partenaires-legende">France Travail</div>
                </a>
                <a href="http://www.amilor.fr" target="_blank" rel="noopener" title="Réseau des Missions Locales Grand Est">
                    <img src="../assets/img/partenaires/27armlge.png" alt="27armlge" width="250" height="118" />
                    <div class="partenaires-legende">Association Régionale des Missions locales Grand Est</div>
                </a>
                <a href="https://www.cheops-ops.org" target="_blank" rel="noopener" title="Cheops, Conseil national Handicap &amp; Emploi des Organismes de Placement Spécialisés">
                    <img src="../assets/img/partenaires/15cheops.svg" alt="Cheops, Conseil national Handicap &amp; Emploi des Organismes de Placement Spécialisés" width="250" height="auto" />
                    <div class="partenaires-legende">Conseil National Handicap Emploi des Organismes de Placement Spécialisés</div>
                </a>
            </div>

            <div class="bloc-partenaires">
                <a href="https://www.agefiph.fr" target="_blank" rel="noopener" title="Agefiph, Association de gestion du fonds pour l'insertion professionnelle des personnes handicapées"> <img src="../assets/img/partenaires/16agefiph.png" alt="Agefiph, Association de gestion du fonds pour l'insertion professionnelle des personnes handicapées" width="250" height="114" />
                    <div class="partenaires-legende">Association de gestion du fonds pour l'insertion professionnelle des personnes handicapées</div>
                </a>
                <a href="https://www.apec.fr" target="_blank" rel="noopener" title="APEC">
                    <img src="../assets/img/partenaires/14apecge.png" alt="APEC" width="250" height="192" />
                    <div class="partenaires-legende">Association Pour l'Emploi des Cadres</div>
                </a>
            </div>

            <div class="partenaires-devide">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>

            <div class="bloc-partenaires">
                <a href="https://www.transitionspro-grandest.fr/" target="_blank" rel="noopener" title="Transitions Pro Grand Est">
                    <img src="../assets/img/partenaires/1601transition.svg" alt="Transitions Pro Grand Est" width="250" height="auto" />
                    <div class="partenaires-legende">Transitions Pro Grand Est</div>
                </a>
                <a href="https://www.mon-service-cep.fr/region/grandest" target="_blank" rel="noopener" title="Mon Conseil en Evolution Professionnelle Grand Est">
                    <img src="../assets/img/partenaires/1602cep.svg" alt="Mon Conseil en Evolution Professionnelle Grand Est" width="250" height="auto" />
                    <div class="partenaires-legende">Mon Conseil en Evolution Professionnelle Grand Est</div>
                </a>
            </div>

            <div class="partenaires-devide">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>

            <div class="bloc-partenaires">
                <a href="http://www.semaphore.asso.fr/" target="_blank" rel="noopener" title="Sémaphore  MSA">
                    <img src="../assets/img/partenaires/17semaphore.png" alt="Sémaphore  MSA" width="250" height="273" />
                    <div class="partenaires-legende">Sémaphore</div>
                </a>
                <a href="https://info-jeunes-grandest.fr" target="_blank" rel="noopener" title="Info Jeunes Grand Est">
                    <img src="../assets/img/partenaires/18crij.png" alt="Info Jeunes Grand Est" width="250" height="140" />
                    <div class="partenaires-legende">Info Jeunes Grand Est</div>
                </a>
            </div>

            <div class="partenaires-devide">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>

            <div class="bloc-partenaires">
                <a href="https://www.grandest.cci.fr" target="_blank" rel="noopener" title="CCI Grand Est">
                    <img src="../assets/img/partenaires/09ccige.png" alt="CCI Grand Est" width="250" height="64" />
                    <div class="partenaires-legende">Chambre de Commerce et d'Industrie Grand Est</div>
                </a>
                <a href="https://grandest.chambre-agriculture.fr" target="_blank" rel="noopener" title="Chambre d'Agriculture Grand Est">
                    <img src="../assets/img/partenaires/10crage.png" alt="Chambre d'Agriculture Grand Est" width="250" height="271" />
                    <div class="partenaires-legende">Chambre d'Agriculture Grand Est</div>
                </a>
                <a href="https://crma-grandest.fr" target="_blank" rel="noopener" title="Chambre des Métiers et de l'Artisanat Grand Est">
                    <img src="../assets/img/partenaires/11crmage.png" alt="Chambre des Métiers et de l'Artisanat Grand Est" width="250" height="280" />
                    <div class="partenaires-legende">Chambre des Métiers et de l'Artisanat Grand Est</div>
                </a>
                <a href="http://www.cress-grandest.org/" target="_blank" rel="noopener" title="Chambre Régionale de l'Economie Sociale et Solidaire Grand Est">
                    <img src="../assets/img/partenaires/12cressge.png" alt="Chambre Régionale de l'Economie Sociale et Solidaire Grand Est" width="250" height="95" />
                    <div class="partenaires-legende">Chambre de l'Economie Sociale et Solidaire Grand Est</div>
                </a>
            </div>

            <div class="partenaires-devide">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>

            <div class="bloc-partenaires">
                <a href="http://www.univ-lorraine.fr" target="_blank" rel="noopener" title="Université de Lorraine">
                    <img src="../assets/img/partenaires/05univl.png" alt="Université de Lorraine" width="250" height="94" />
                    <div class="partenaires-legende">Université de Lorraine</div>
                </a>
                <a href="https://www.unistra.fr" target="_blank" rel="noopener" title="Université de Strasbourg">
                    <img src="../assets/img/partenaires/06univs.png" alt="Université de Strasbourg" width="250" height="95" />
                    <div class="partenaires-legende">Université de Strasbourg</div>
                </a>
                <a href="https://www.univ-reims.fr" target="_blank" rel="noopener" title="Université de Reims Champagne-Ardenne">   <img src="../assets/img/partenaires/07univr.png" alt="Université de Reims Champagne-Ardenne" width="250" height="153" />
                    <div class="partenaires-legende">Université de Reims Champagne-Ardenne</div>
                </a>
                <a href="https://www.uha.fr" target="_blank" rel="noopener" title="Université Haute-Alsace">
                    <img src="../assets/img/partenaires/08univha.png" alt="Université Haute-Alsace" width="250" height="96" />
                    <div class="partenaires-legende">Université Haute-Alsace</div>
                </a>
            </div>

        </div>
    </div>

    <bloc-footer/>
  </div>
</template>


<script>
import Header2 from '@/components/Header2.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'Partenaires',
    props: {
        sitePro: {
            type: Boolean
        },
    },
    computed:{
        logoRGE() {
            if(this.$router.options.paramNouveauLogo) {
                return require("../assets/img/partenaires/02rge2022.svg");
            }
            else {
                return require("../assets/img/partenaires/02rge.svg");
            }
        }
    },
    created() {
        if (this.sitePro) {
            this.$parent.sitePRO = true;
        }
    },
    data() {
        return {
            tmp:true,
        }
    },
    components: {
        'bloc-header2': Header2,
        'bloc-footer': Footer,
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #partenaires {
        #container-partenaires {
			/*padding-top:50px;*/

            &> h1 {
                text-align: center;
            }

            .partenaires {
                text-align:center;
                font-size: 15px;

                a {
                    display: inline-block;
                    margin : 1em;
                    padding: 2em;
                    max-width: 160px;
                    max-height: 160px;
                    width: 18em;
                    height: 15em;
                    vertical-align: top;
                    border: 1px solid rgba(0, 0, 0, .05);
                    box-shadow: 5px 5px 8px rgba(0, 0, 0, .2);
                    position: relative;
                    transition: .1s ease-in-out;
                }

                img {
                    width: auto;
                    margin: 0;
                    max-height: 65%;
                    top: 47%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                    /*max-width: 90%;  */
                    max-width: 198px;
                    height: auto;
                    &.logoRGE {
                        width: 100%;
                    }
                }
            }

            .partenaires-legende {
                color:#444;
                font-style: italic;
                position: absolute;
                bottom: 0;
                line-height:1em;
                font-weight: normal;
                font-size: 12px;
                text-align: center;
                opacity: 0;
                transition: 0.2s ease-in-out;
                width: 80%;
                margin: 15px 0;
            }

            .bloc-partenaires > a:hover .partenaires-legende, .bloc-partenaires > a:focus .partenaires-legende {
                opacity: 1;
            }
            .bloc-partenaires > a:hover, .bloc-partenaires > a:focus {
                transform: translateY(-3px);
            }

            .partenaires-devide {
			     margin: 45px auto 30px auto;

                &> div {
                    width: .5em;
                    height: .5em;
                    display: inline-block;
                    border-radius: 50%;
                    background-color: #999;
                }
                &> div:not(:last-child) {
			         margin-right: .5em;
                }
            }

            .title {

                h1 {
                    font-size: 18px;
                    text-align: center;
                    margin-bottom: 30px;

                    img {
                        margin: auto;
                    }

                    .rge {
			             font-weight: bold;
                    }
                }

                .partenariat {
			         margin-top: 30px;
                }
            }
        }
    }
</style>
